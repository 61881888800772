<template>
  <div>
    <h3>车牌号</h3>
    <div class="plate-box">
      <vnp-input v-model="search"></vnp-input>
    </div>
    <div class="plate-button">
      <van-button type="warning" size="small" icon="clear" @click="clear">清空</van-button>
      <van-button type="info" size="small" icon="search" @click="dosearch">查询</van-button>
    </div>
    <van-row v-if="result || loading">
      <van-col span="24" class="result">
        <van-loading v-if="loading" class="loading" color="#1989fa" />
        <div v-else class="content"><van-icon color="#1989fa" size="1.4rem" name="vip-card"/> <span v-text="result"></span></div>
      </van-col>
    </van-row>
    <van-empty v-else image="search" description="请输入车牌号!!" />
  </div>
</template>

<script>
import VnpInput from '@/components/vant-number-plate/vnp-input';
import { Toast } from 'vant';

export default {
  name: 'App',
  components: {
    'vnp-input': VnpInput
  },
  data() {
    return {
      show: false,
      loading: false,
      search: '粤',
      result: '',
    }
  },
  methods: {
    clear() {
      this.search = '';
      this.result = '';
    },
    dosearch() {
      if (this.search.length >= 7) {
        this.loading = true;
        this.result =  '';
        this.$http.get('/api/checkplat', {params: {'plat': this.search}}).then((response) => {
          this.loading = false; 
          if (response.status == 200) {
            const data = response.data;
            if (data.code > 0) {
              const info = data.info;
              console.log(info);
              this.result = info.number + ' - ' + info.showdetail + (info.isvip == 1?'月卡车':'临时车');
            } else {
              console.log(this);
              Toast.fail(data.message ?? '查询失败，请稍后重试！');
            }
          }
        });
      } else {
        Toast.fail('请输入完整车牌号码！');
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .plate-box {
    padding: 0 30px;
  }
  .plate-button {
    padding: 0 30px;
    text-align: center;
    margin-top: 10px;
    button {
      margin: 0px 10px;
      width: 100px;
    }
  }
  h3 {
    padding: 0 30px;
  }
  .result {
    text-align: center;
    padding: 15px 30px;
    height: 180px;
    display:flex;
  }
  .loading, .content {
    align-self:center;
    left: 50%;
  }
</style>
