import Vue from 'vue'
import Vant from "vant";
import axios from 'axios'
import "vant/lib/index.css";

import App from "./App.vue";

Vue.use(Vant);
Vue.config.productionTip = false;
Vue.prototype.$http = axios;

new Vue({
  render: h => h(App),
}).$mount('#app')
