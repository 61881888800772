<template>
  <div>
    <vnp-input-box :value="val" @click.native="show = true"></vnp-input-box>
    <vnp-keyboard :show.sync="show" v-model="val" @addNum="addNum(...arguments)" @delNum="delNum(...arguments)"></vnp-keyboard>
  </div>
</template>

<script>
import Box from "./vnp-input-box";
import Keyboard  from "./vnp-keyboard";

export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  components: {
    'vnp-input-box': Box,
    'vnp-keyboard': Keyboard
  },
  data() {
    return {
      show: false,
      arr: [],
    };
  },
  computed: {
    val: {
      set(v) {
        this.$emit("input", v);
      },
      get() {
        return this.value;
      },
    }
  },
  methods: {
    addNum(index, val) {
      this.arr = this.val.split("");
      this.arr[index] = val;
      this.val = this.arr.join("");
    },
    delNum(index) {
      this.arr = this.val.split("");
      this.arr[index] = ' ';
      this.val = this.arr.join("");
    }
  }
};
</script>
