<template>
  <div id="app">
    <Plate></Plate>
  </div>
</template>

<script>
import Plate from '@/components/plate';

export default {
  components: {
    Plate
  }
}
</script>
